import React from 'react';
import {Box, CircularProgress} from '@material-ui/core'

const Loader = () => {
  return (
    <Box height="25vh" display="flex" justifyContent="center" alignItems="center">
      <CircularProgress color="primary" />
    </Box>
  );
};

export default Loader;