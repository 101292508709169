import React, {Suspense} from 'react';
import {
  MuiThemeProvider,
  makeStyles,
  CssBaseline,
  createTheme,
} from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import {lightBlue, orange,} from "@material-ui/core/colors";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Loader from "./components/Loader";

// import waves from './assets/Waves.svg';
import waves from './assets/Hexagon.svg';

const Me = React.lazy(() => import('./components/Me'));
// const Projects = React.lazy(() => import('./components/Projects'));
const Technologies = React.lazy(() => import('./components/Technologies'));
const Skills = React.lazy(() => import('./components/Skills'));
const Clients = React.lazy(() => import('./components/Clients'));
const Contact = React.lazy(() => import('./components/Contact'));

const customBreakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  }
})
const theme = createTheme({
  breakpoints: {
    ...customBreakpoints,
  },
  palette: {
    type: 'dark',
    primary: {
      main: lightBlue["200"]
    },
    secondary: {
      main: orange["200"]
    },
    text: {
      primary: "#fff"
    },
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          overflow: 'hidden',
          [customBreakpoints.down("sm")]: {
            overflow: 'auto',
          }
        }
      }
    },
  },
})

const useStyles = makeStyles(theme => ({
  main: {
    // padding: theme.spacing(7, 0),
    position: "relative",
    scrollSnapType: "y mandatory",
    scrollBehavior: 'smooth',
    // display: 'flex',
    // flexDirection: 'column',
    height: `calc(100vh - ${theme.spacing(16)}px - 2px)`,
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      // padding: theme.spacing(3,0),
      scrollSnapType: "none",
      height: "auto",
    },
    backgroundImage: `url(${waves})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
  },
}))


function App() {
  const styles = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline/>
      <Header />
      <main className={styles.main}>
        <Suspense fallback={<Loader />}>
          <Me />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Skills />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Technologies />
        </Suspense>
        {/*<Suspense fallback={<Loader />}>*/}
        {/*  <Projects />*/}
        {/*</Suspense>*/}
        <Suspense fallback={<Loader />}>
          <Clients />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Contact />
        </Suspense>
      </main>
      <Footer />
    </MuiThemeProvider>
  );
}

export default App;
