import React, {useState} from 'react';
import {
  AppBar,
  makeStyles,
  Toolbar,
  SvgIcon,
  useTheme,
} from '@material-ui/core';
import  { ReactComponent as AD } from "../assets/alexdoumas.svg";

const useStyles = makeStyles(theme => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.background.paper}`,
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    justifyContent: "center",
  },
  logo: {
    transition: theme.transitions.create(['fill'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  }
}));

const Header = () => {
  const styles = useStyles();
  const theme = useTheme();
  const [hover, setHover] = useState(false)
  return (
    <AppBar color="default" variant="elevation" position="sticky" elevation={0} classes={{root:styles.appBar}}>
      <Toolbar classes={{root:styles.toolbar}}>
        <SvgIcon
          className={styles.logo}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{width:96,height:48, fill: hover ? theme.palette.primary.main : theme.palette.common.white}}
          component={AD} viewBox="0 0 96 48"
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;