import React from 'react';
import {AppBar, makeStyles, Toolbar, Typography} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  appBar: {
    borderTop: `1px solid ${theme.palette.background.paper}`,
    backgroundColor: theme.palette.background.default,
  },
  typography: {
    flexGrow: 1,
  },
}))

const Footer = () => {
  const styles = useStyles();
  return (
    <AppBar color="default" position="static" elevation={0} component="footer" classes={{root: styles.appBar}}>
      <Toolbar>
        <Typography className={styles.typography} component="div" variant="body1" align="center">&copy; Copyright {new Date().getFullYear()} - AlexDoumas.dev</Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;